import BasicComponents from "../../components/basic-components";
import Form from "../../components/form";
import PlaningService from "../../../services/planing-service";
import {getElement} from "bootstrap/js/src/util";
import {fetchMetalRates, fetchNewApi} from "../../../api/get-data";
import date from "date-and-time";
import StorageService from "../../../services/storage-service";
import InvestmentTable, {initInvesmentTable} from "./investment-table";
import tab from "bootstrap/js/src/tab";
import {fetchAndStoreMetals} from "../../../api/metals-cache";
import {fetchAndStoreCurrencies} from "../../../api/currency-cache";

export const initPlaning = async () => {


    // ------------------------------------ header ----------------------------------
    const planing = new BasicComponents()
    planing.createH1("Inwestycje", "mainArea")
    planing.addIconToHeading("fas fa-money-bill-trend-up color-3")
    planing.createP("Zaplanuj ile chcesz wydac na inwestycje", "alert alert-success m-0", "mainArea")
    // -----------------------------------------------------------------------------


    // ----------------------------- kwoty do wyswietlnia ----------------------------
    planing.createAndAppendDiv('ivest-row', 'row mt-4', "mainArea")

    const createCard = (divId, text, AmountId) => {
        planing.createAndAppendDiv(divId, 'col-md-4', "ivest-row")
        planing.createAndAppendDiv(divId + '-wrap', 'card shadow text-center', divId)
        planing.createH2(text, divId + '-wrap', "mt-4")
        planing.createAndAppendDiv(AmountId, "mb-3", divId + '-wrap')
    }

    createCard("card-one", "Do wydania", "start-amont")
    createCard("card-two", "Zainwestowana kwota", "invested-amout")
    createCard("card-three", "Zysk/strata", "sum-profit")

    document.getElementById("start-amont").innerText = localStorage.getItem("saves") || "0"
    // document.getElementById("invested-amout").innerText = localStorage.getItem("invested-amout") || "0"
    document.getElementById("sum-profit").innerText = localStorage.getItem("sum-profit") || "0"

    // ----------------------------- kooniec kwot ----------------------------

    // ----------------------------- formularz ----------------------------
    const walletForm = new Form()
    walletForm.createFormDiv("wallet-container", "container-fluid planing p-4 mt-4 bg-light shadow mb-4 rounded bg-color-3", "mainArea")
    walletForm.createForm("wallet-form", "wallet-container")
    walletForm.createLabel("Tutaj podaj jaka kwotę chcesz zainwestować", "labPodaj", "wallet-form")
    walletForm.createNumberInput("choose", "input form-control mb-4", "wallet-form")
    walletForm.createLabel("Tutaj wybierz opcje w co chesz zainwestowac", "labPodaj", "wallet-form")
    const wf = document.getElementById("wallet-form");

    const hr = document.createElement("hr")
    const hr2 = document.createElement("hr")

    wf.appendChild(hr)

    // Tworzymy pierwszy radio button
    let radio1 = document.createElement('input');
    radio1.type = 'radio';
    radio1.name = 'RadioInvestGroup'; // Oba buttony muszą mieć tę samą nazwę, żeby były w tej samej grupie
    radio1.id = 'metals-radio';
    radio1.value = 'metals';
    radio1.checked = true;

// Tworzymy etykietę dla pierwszego radio buttona
    let label1 = document.createElement('label');
    label1.setAttribute('for', 'metals-radio');
    label1.textContent = 'Metale';

// Tworzymy drugi radio button
    let radio2 = document.createElement('input');
    radio2.type = 'radio';
    radio2.name = 'RadioInvestGroup'; // Ta sama nazwa, aby były w tej samej grupie
    radio2.id = 'currencies';
    radio2.value = 'currencies';

// Tworzymy etykietę dla drugiego radio buttona
    let label2 = document.createElement('label');
    label2.setAttribute('for', 'currencies');
    label2.textContent = 'Waluty';

// Dodajemy radio buttony i etykiety do dokumentu
    wf.appendChild(radio1);
    wf.appendChild(label1);
    wf.appendChild(document.createElement('br')); // Przerwa między elementami
    wf.appendChild(radio2);
    wf.appendChild(label2);
    wf.appendChild(hr2)

    // await fetchAndStoreMetals()

    const metalsStorage = new StorageService("metals")
    const currStorage = new StorageService("currencies-today")

    walletForm.createSelect("investSelect", "form-control mb-4", "wallet-form", metalsStorage.getAllKeys());
    walletForm.createFormDiv("metal-price", "price mb-4 bg-color-2 p-4 color-5", "wallet-form");
    document.getElementById("metal-price").textContent = `Cena za gram wynosi: ${metalsStorage.findOne("aluminium")}`
    document.getElementById("investSelect").addEventListener("change", function () {
        document.getElementById("metal-price").textContent = `Cena za gram wynosi: ${metalsStorage.findOne(this.value)}`
    });

    //---------------------------------------------------
    //---------------------------------------------------

    const currencyStorage = new StorageService('currencies-today');
    console.log([...currencyStorage.findAll()]);
    const currForSelect = [...currencyStorage.findAll()].map((item) => {
        return (item.currency);
    })

    walletForm.createSelect("investCurrenciesSelect", "form-control mb-4", "wallet-form", currForSelect);
    walletForm.createFormDiv("curr-price", "price mb-4 bg-color-2 p-4 color-5", "wallet-form");
    document.getElementById("curr-price").textContent = `Cena waluty: ${currencyStorage.findOne("bat (Tajlandia)").mid}`
    document.getElementById("investCurrenciesSelect").addEventListener("change", function () {
        document.getElementById("curr-price").textContent = `Cena waluty ${currencyStorage.findOne(this.value).mid}`
    });


    //---------------------------------------------------
    //---------------------------------------------------

    const radios = document.getElementsByName('RadioInvestGroup');
    const metalsSelect = document.getElementById('investSelect');
    const currenciesSelect = document.getElementById('investCurrenciesSelect');
    const metalPriceDiv = document.getElementById('metal-price');
    const currPriceDiv = document.getElementById('curr-price');

    metalsSelect.style.display = 'block';
    metalPriceDiv.style.display = 'block';
    currenciesSelect.style.display = 'none';
    currPriceDiv.style.display = 'none';

    for (const radio of radios) {
        radio.addEventListener('change', (event) => {
            if (event.target.checked) {
                if (event.target.value === "metals") {
                    metalsSelect.style.display = 'block';
                    metalPriceDiv.style.display = 'block';
                    currenciesSelect.style.display = 'none';
                    currPriceDiv.style.display = 'none';
                } else {
                    metalsSelect.style.display = 'none';
                    metalPriceDiv.style.display = 'none';
                    currenciesSelect.style.display = 'block';
                    currPriceDiv.style.display = 'block';
                }
            }
        });
    }

    walletForm.createButton("submit-invest", "btn btn-secondary mb-4", "Zatwierdź", "submit", "wallet-form")

    //----------------------------- koniec formularza -----------------------

    const inputElement = document.getElementById("choose")

    inputElement.setAttribute('required', true);
    inputElement.setAttribute('step', '0.01');

    //----------------------------- kontener na invetycje -----------------------
    planing.createAndAppendDiv("investContainer", "table-responsive", "mainArea")
    planing.createH2("Twoje inwestycje", "investContainer", "mt-4")


    //----------------------------- Archiwum iwestycji -----------------------
    planing.createAndAppendDiv("archiveContainer", "table-responsive", "mainArea")
    planing.createH2("Archiwum investycji", "archiveContainer", "mt-4")

    //----------------------------- clear Buton -----------------------
    walletForm.createButton("clear-button", "btn btn-secondary mb-4", "wyczyść archiwum", "submit", "mainArea")



    //------------------------------------------------------------------------------

    const plan = new PlaningService()

    //---------------------------- Pobieranie Kwot ----------------------------------
    // const startAmount = document.getElementById("startAmont")
    // const investedAmout = document.getElementById("investedAmout")
    // const profit = document.getElementById("profit")
    // const initAmount = () => {
    //     startAmount.innerText = (plan.getCurrentSaves() || 0).toString()
    //     profit.innerText = (plan.getCurrentSaves() - plan.countInvestments()).toString()
    //     investedAmout.innerText = plan.countInvestments()
    // }

    const table = new InvestmentTable("investContainer")
    table.createTable("invest-body", "Nazwa", "Rodzaj", "Wartość inwestycji", "Cena zakupu", "Data zakupu", "Obecna cena sprzedazy", "Obecna Wartość inwestycji", "Zysk/strata", "Sprzedaj")

    const archive = new InvestmentTable("archiveContainer")
    archive.createTable("archive-body", "Nazwa", "Rodzaj", "Wartość investycji", "Cena zakupu", "Data zakupu", "Data sprzedazy", "cena sprzedazy", "zysk/strata")

    document.getElementById("clear-button").addEventListener("click", e => {
        e.preventDefault()
        plan.clearArchive()
        document.getElementById("archive-body").innerHTML = "";

        //archive.createTable("archive-body", "Nazwa", "Rodzaj", "Wartość investycji", "Cena zakupu", "Data zakupu", "Data sprzedazy", "cena sprzedazy", "zysk/strata")
    })


    const form = document.getElementById("wallet-form")
    const inBody = document.getElementById("invest-body")

    const createInvestment = (investment) => {

        let profit, currentPrice

        console.log(investment)

        if (investment.activeRadio === "metals") {
            profit = ((investment.amount / investment.price - investment.amount / metalsStorage.findOne(investment.name)) * metalsStorage.findOne(investment.name)).toFixed(2)
            currentPrice = metalsStorage.findOne(investment.name)
        } else {
            profit = ((investment.amount / investment.price - investment.amount / currStorage.findOne(investment.name).mid) * currStorage.findOne(investment.name).mid).toFixed(2)
            currentPrice = currStorage.findOne(investment.name).mid
        }


        const btn = table.addInvestRow(
            investment.id,
            `${investment.name}`,
            `${investment.activeRadio === "metals" ? "metal" : "waluta"}`,
            `${investment.amount}`,
            `${investment.price}`,
            `${investment.date}`,
            // `${(investment.quantity).toFixed(2)}`,
            `${currentPrice}`,
            `${Number.parseFloat(investment.amount) + Number.parseFloat(profit)}`,
            `${profit}`
        )

        btn.onclick = () => {
            document.getElementById("invest-body").innerHTML = ""
            document.getElementById("archive-body").innerHTML = ""
            plan.sellInvestment(investment)
            document.getElementById("start-amont").innerText = `${localStorage.getItem("saves") || "0"}`
            document.getElementById("sum-profit").innerText = `${localStorage.getItem("current-profit") || "0"}`
            initInvestments()
            initArchive()
        }
    }

    const createArchive = (investment) => {
        // let profit, currentPrice

        // if (investment.activeRadio === "metals") {
        //     profit = ((investment.amount / investment.price - investment.amount / metalsStorage.findOne(investment.name)) * metalsStorage.findOne(investment.name)).toFixed(2)
        //     currentPrice = metalsStorage.findOne(investment.name)
        // } else {
        //     profit = ((investment.amount / investment.price - investment.amount / currStorage.findOne(investment.name).mid) * currStorage.findOne(investment.name).mid).toFixed(2)
        //     currentPrice = currStorage.findOne(investment.name).mid
        // }

        archive.addArchiveRow(
            `${investment.name}`,
            `${investment.activeRadio === "metals" ? "metal" : "waluta"}`,
            `${investment.amount}`,
            `${investment.price}`,
            `${investment.date}`,
            `${investment.dateOfSell}`,
            // `${(investment.quantity).toFixed(2)}`,
            `${investment.currentPrice}`,
            `${investment.profit}`)
    }

    function initInvestments() {
        if (plan.getAllInvestments()) {
            plan.getAllInvestments().forEach((investment) => {
                createInvestment(investment)
            })
        }

        const sumInvest = plan.getAllInvestments().reduce((acc, current) => {
                console.log("to jes curr", acc, current);
                return acc + Number.parseFloat(current.amount)
            }
            , 0)

        // Dodaj inwestycję z wybraną wartością

        localStorage.setItem("invested-amout", `${sumInvest}`)
        document.getElementById("invested-amout").innerText = localStorage.getItem("invested-amout") || "0"


    }

    function initArchive() {
        if (plan.getAllArchives()) {
            plan.getAllArchives()
                .forEach((investment) => {
                    createArchive(investment)
                })
        }

        const sumInvest = plan.getAllInvestments().reduce((acc, current) => {
                console.log("to jes curr", acc, current);
                return acc + Number.parseFloat(current.amount)
            }
            , 0)

        localStorage.setItem("invested-amout", `${sumInvest}`)
        document.getElementById("invested-amout").innerText = localStorage.getItem("invested-amout") || "0"
    }


    // --------------------------- Funkcja inicjujaca inwestycje -----------------

    initInvestments()
    initArchive()

    form.addEventListener("submit", (e) => {
        e.preventDefault();

        const saves = Number.parseFloat(localStorage.getItem("saves") || 0)

        if (saves <= 0) {
            alert("Wartoś saves musi być wieksza od zera")
        } else if (saves - inputElement.value < 0) {
            alert("Wartoś podanej inwestycji przekracza oszczednosci")
        }

        else {
            localStorage.setItem("saves", `${saves - inputElement.value}`)
            const activeRadio = document.querySelector('input[name="RadioInvestGroup"]:checked');
            let value;

            if (activeRadio) {
                if (activeRadio.value === "metals") {
                    value = document.getElementById("investSelect").value; // Zmienna metali
                } else {
                    value = document.getElementById("investCurrenciesSelect").value; // Zmienna waluty
                }
            }

            plan.addInvestment(inputElement.value, value, activeRadio.value);
            inBody.innerHTML = "";
            initInvestments();

            document.getElementById("start-amont").innerText = localStorage.getItem("saves") || "0"

        }


    })
}

