export default class InvestmentTable {
    constructor (container) {
        this.investmentContainer = document.getElementById(container)
        this.table = document.createElement("table")
        this.thead = document.createElement("thead")
        this.tbody = document.createElement("tbody")
        this.thtr = document.createElement("tr")
        //this.tbtr = document.createElement("tr")
    }

    createTable (idBody, ...headers) {
        this.table.className = "table table-striped table-hover text-center"
        this.tbody.id = idBody
        headers.forEach(header => this.#addTdToTh(header))
        this.investmentContainer.appendChild(this.table)
        this.table.appendChild(this.thead)
        this.table.appendChild(this.tbody)
        this.thead.appendChild(this.thtr)
        //this.tbody.appendChild(this.tbtr)
    }

    addInvestRow(id, ...values) {
        const tbtr = document.createElement("tr")
        values.forEach(value => this.#addTdToTr(value, tbtr));
        const btnContainer = document.createElement("td")
        const btn = document.createElement("button")
        tbtr.appendChild(btnContainer)
        btn.innerHTML = "<i class='fa fa-circle-check'></i>"
        btn.className = "btn btn-dark"
        btnContainer.appendChild(btn)
        this.tbody.appendChild(tbtr)
        return btn
    }

    addArchiveRow(...values) {
        const tbtr = document.createElement("tr")
        values.forEach(value => this.#addTdToTr(value, tbtr));
        this.tbody.appendChild(tbtr)
    }

    #addTdToTh(title){
        const th = document.createElement("th")
        th.innerText = title
        this.thtr.appendChild(th)
    }

    #addTdToTr(value, tbtr) {
        const td = document.createElement("td")
        td.className = "align-middle"
        td.innerText = value
        tbtr.appendChild(td)
    }

}