import StorageService from "../services/storage-service";
import date from "date-and-time";
import {fetchMetalRates} from "./get-data"; // Zaimportuj swój StorageService

export async function fetchAndStoreMetals() {
    const metalsStorage = new StorageService('metals');
    const today = new Date();
    const formattedDate = date.format(today, 'YYYY-MM-DD');

    // Sprawdź, czy data ostatniego pobrania różni się od dzisiejszej
    if (localStorage.getItem('lastFetchDate') !== formattedDate || !localStorage.getItem('metals')) {
        const res = await fetchMetalRates(); // Załóżmy, że masz funkcję fetchMetalRates, która pobiera dane z API

        // Funkcja pomocnicza do zapisywania metali
        const getMetalFromApiAndLocalSave = (key, metalPL) => {
            metalsStorage.saveWithId(metalPL, res.metals[key]);
        };

        // Zapisz metale w localStorage
        getMetalFromApiAndLocalSave('aluminum', 'aluminium');
        getMetalFromApiAndLocalSave('copper', 'miedź');
        getMetalFromApiAndLocalSave('gold', 'złoto');
        getMetalFromApiAndLocalSave('lead', 'ołów');
        getMetalFromApiAndLocalSave('nickel', 'nikiel');
        getMetalFromApiAndLocalSave('palladium', 'palad');
        getMetalFromApiAndLocalSave('platinum', 'platyna');
        getMetalFromApiAndLocalSave('silver', 'srebro');
        getMetalFromApiAndLocalSave('zinc', 'cynk');
        // Zapisz datę ostatniego pobrania
        localStorage.setItem('lastFetchDate', formattedDate);
    }
}