export default class StorageService {

    constructor(key) {
        this.key = key;
        this.storageMap = new Map(Object.entries(JSON.parse(localStorage.getItem(this.key) || '{}' )));
    }

    findOne(id) {
        return this.storageMap.get(id);
    }

    findAll() {
        return this.storageMap.values()
    }

    getAllKeys() {
        return this.storageMap.keys()
    }

    // findAllKeyValues() {
    //     return this.storageMap.entries()
    // }

    save(obj) {
        const id = Date.now().toString();
        obj["id"] = id
        this.storageMap.set(id, obj);
        const toSave = this.#mapToObject(this.storageMap);
        localStorage.setItem(this.key, JSON.stringify(toSave));
    }

    saveWithId(id, obj) {
        this.storageMap.set(id, obj);
        const toSave = this.#mapToObject(this.storageMap);
        localStorage.setItem(this.key, JSON.stringify(toSave));
    }

    remove(id) {
        this.storageMap.delete(id);
        const toSave = this.#mapToObject(this.storageMap);
        localStorage.setItem(this.key, JSON.stringify(toSave));
    }

    #mapToObject(map) {
        return Object.fromEntries(map.entries());
    }

    clear() {
        this.storageMap.clear();
        localStorage.setItem(this.key, '{}');
    }
}
