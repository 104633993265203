 import {createHome} from "../ui/pages/home";
 import createCurrencyChart from "../ui/chart";
 import {fillData} from "../ui/pages/currencies/table-currencies";
 import ApexCharts from "apexcharts";
 import {options} from "../ui/chart";
 import {initWallet} from "../ui/pages/investments/wallet";
 import {initBudget} from "../ui/pages/budget/budget-main";
 import {initInstruction} from "../ui/pages/instruction/instruction";
 import {initPlaning} from "../ui/pages/investments/planing";
 import {initBruttoNetto} from "../ui/pages/gross-net/gross-net";
 import {initGold} from "../ui/pages/gold/gold";
 import {initCurrencies} from "../ui/pages/currencies/currencies";
 import {initCurrCalculator} from "../ui/pages/curr-calculator/curr-calculator";
 import {initMetals} from "../ui/pages/metals/metals";



export const handlers = () => {
    const buttHome = document.getElementById("home")
    buttHome.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        createHome().catch(console.error)
    })

    const buttGold = document.getElementById("gold")
    buttGold.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initGold().catch(console.error)
    })

    const buttCurr = document.getElementById("currency")
    buttCurr.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initCurrencies()
    })

    const buttMetals = document.getElementById("metals")
    buttMetals.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initMetals()
    })


    const buttCurrCalculator = document.getElementById("curr-calculator")
    buttCurrCalculator.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initCurrCalculator()
    })

    // const buttWallet = document.getElementById("wallet")
    // buttWallet.addEventListener("click", (e) => {
    //     document.getElementById("mainArea").innerHTML = ""
    //     initWallet()
    // })

    const buttBudget = document.getElementById("budget")
    buttBudget.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initBudget()
    })

    const buttBrutNette = document.getElementById("butto-netto")
    buttBrutNette.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initBruttoNetto()
    })

    const buttIns = document.getElementById("instruction")
    buttIns.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initInstruction()
    })

    const buttPlan = document.getElementById("plannig")
    buttPlan.addEventListener("click", (e) => {
        document.getElementById("mainArea").innerHTML = ""
        initPlaning()

        // const val = document.querySelector(".budgetValue")
        // val.innerHTML = localStorage.getItem("balance")

    })


    // const form = document.getElementById("walletForm")
    // form.addEventListener('submit', (event) => {
    //     event.preventDefault();
    // });



    // const buttAddOutgoing = document.getElementById("buttOutgoig")
    // buttAddOutgoing.addEventListener("submit", (e) => {
    //     e.preventDefault();
    // })

}