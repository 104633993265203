import date from 'date-and-time';
import StorageService from "./storage-service"

export default class PlaningService {
    constructor() {
        this.saves = localStorage.getItem("saves")
        this.investStorage = new StorageService("investments");
        this.metalsStorage = new StorageService("metals");
        this.archiveStorage = new StorageService("archives-investments");
        this.currStorage = new StorageService("currencies-today");
    }

    getCurrentSaves() {
        return this.saves
    }

    getAllInvestments() {
        // this.investStorage.findAll().forEach(investment => {
        //             //investment.sellPrice = this.metalsStorage.findOne(investment.name)
        //             //investment.sellPrice = this.currStorage.findOne(investment.name)
        //     //console.log("asdsadsadsasa", this.currStorage.findOne(investment.name).mid)
        //     investment.sellPrice = this.metalsStorage.findOne(investment.name) || this.currStorage.findOne(investment.name).mid
        //             //investment.profit = (investment.amount / investment.metalPrice - investment.amount / investment.sellPrice) * investment.sellPrice
        //         })
        return this.investStorage.findAll()
    }

    // getAllInvestmentsWithProfit() {
    //     this.investStorage.findAll().forEach(investment => {
    //         investment.sellPrice = this.metalsStorage.findOne(investment.name)
    //         //investment.profit = (investment.amount / investment.metalPrice - investment.amount / investment.sellPrice) * investment.sellPrice
    //     })
    //     return this.investStorage.findAll()
    // }

    getAllArchives() {
        return this.archiveStorage.findAll();
    }

    addInvestment(amount = 0, name = "", active="metals") {
        const investDate = new Date()
        console.log(this.currStorage.findOne(name))
        const value = {
                name,
                date: date.format(investDate, 'DD/MM/YYYY'),
                amount,
                price: this.metalsStorage.findOne(name) || this.currStorage.findOne(name).mid,
                activeRadio: active

                //sellPrice:
                //quantity: amount / this.metalsStorage.findOne(name),
        }

        this.investStorage.save(value);
    }

    getSingleInvestment(keyInvestment) {
        console.log(localStorage.getItem(keyInvestment.value()));
        return localStorage.getItem(keyInvestment.value())
    }

    sellInvestment(investment) {
        let profit, currentPrice

        if (investment.activeRadio === "metals") {
            profit = ((investment.amount / investment.price - investment.amount / this.metalsStorage.findOne(investment.name)) * this.metalsStorage.findOne(investment.name)).toFixed(2)
            currentPrice = this.metalsStorage.findOne(investment.name)
        } else {
            profit = ((investment.amount / investment.price - investment.amount / this.currStorage.findOne(investment.name).mid) * this.currStorage.findOne(investment.name).mid).toFixed(2)
            currentPrice = this.currStorage.findOne(investment.name).mid
        }

        this.investStorage.remove(investment.id);
        investment.dateOfSell = date.format( new Date(), "DD/MM/YYYY" );
        investment.profit = profit
        investment.currentPrice = currentPrice
        // investment.sellPrice = this.metalsStorage.findOne(investment.name)
        this.archiveStorage.save(investment);

        const updatedSaves = `${Number.parseFloat(localStorage.getItem("saves")) + Number.parseFloat(investment.amount) + Number.parseFloat(profit)}`
        localStorage.setItem("saves", updatedSaves );

        const updatedProfit = `${Number.parseFloat(localStorage.getItem("current-profit") || "0" ) + Number.parseFloat(profit)}`
        localStorage.setItem("current-profit", updatedProfit );

    }

    clearArchive() {
        this.archiveStorage.clear()
    }


    countInvestments() {
        const arr = this.getAllInvestments()
        return arr.reduce((acc, item) => {
            console.log("acc", item)
            acc += Number.parseFloat(item.investmentAmount);
            return acc
        }, 0)
    }
}
