import axios from 'axios';
import date from "date-and-time";

export async function fetchCurrencyData(currency, last) {
    try {
        return await axios.get(`https://api.nbp.pl/api/exchangerates/rates/a/${currency}/last/${last}/?format=json`)
    } catch (error) {
        console.error('Wystąpił błąd!', error);
    }
}

export async function fetchTableData(table) {
    try {

        return await axios.get(`https://api.nbp.pl/api/exchangerates/tables/${table}`)
    } catch (error) {
        console.error('Wystąpił błąd!', error);
    }
}

export async function fetchTableDataLast(table, topCount) {
    try {

        return await axios.get(`https://api.nbp.pl/api/exchangerates/tables/${table}/last/${topCount}/`)
    } catch (error) {
        console.error('Wystąpił błąd!', error);
    }
}

export async function fetchGoldData(last) {
    try {
        return await axios.get(`https://api.nbp.pl/api/cenyzlota/last/${last}`)
    } catch (error) {
        console.error('Wystąpił błąd!', error);
    }
}

export async function fetchGoldDataFromDate(dateGold) {
    //const newDate = date.format(dateGold, "YYYY-MM-DD");

    try {
        return await axios.get(`https://api.nbp.pl/api/cenyzlota/${dateGold}/?format=json`);
    } catch (error) {
        console.error('Wystąpił błąd!', error.response?.status);  // Upewniamy się, że mamy dostęp do statusu odpowiedzi

        // if (error.response?.status === 404) {
        //      Dodajemy dwa dni do pierwotnej daty
        //     let updatedDate = new Date(dateGold);
        //     updatedDate.setDate(updatedDate.getDate() + 2);
        //
        //     const newTestDate = date.format(updatedDate, "YYYY-MM-DD");
        //     return await axios.get(`https://api.nbp.pl/api/cenyzlota/${newTestDate}/?format=json`);
        // }
    }
}

 
export async function fetchGoldRage(startDate, endDate) {
        
    try {
        return await axios.get(`https://api.nbp.pl/api/cenyzlota/${startDate}/${endDate}`)
    } catch (error) {
        //console.error(error);
        // alert(error.response.statusText)
        if (error.response.statusText === "Przekroczony limit 367 dni / Limit of 367 days has been exceeded"){
           alert("Przekroczony limit 367 dni")
        }
        else if (error.response.statusText === "Not Found - Brak danych"){
            alert("Brak danych")
        }
        else if(error.response.data === "400 BadRequest - Błędny zakres dat / Invalid date range"){
            alert("Błędny zakres dat")
        } else {
            alert("Nieznany błąd")
        }

        return null 
    }
}


export async function fetchNewApi(url, params) {
    try {
        const response = await axios.get(url, { params }); // Oczekujemy odpowiedzi
        console.log(response.data); // Obsługa odpowiedzi
        return response.data; // Zwracamy dane
    } catch (error) {
        console.error('Błąd:', error); // Obsługa błędów
        throw error; // Rzucamy błąd dalej, aby funkcja zwróciła wyjątek
    }
}

export async function fetchMetalRates() {
    const url = 'https://api.metals.dev/v1/latest';

    try {
        const response = await axios.get(url, {
            headers: {
                'Accept': 'application/json',
            },
            params: {
                api_key: '8RZ7DU2Q2EGRWDIFCVMK985IFCVMK',
                currency: 'PLN',
                unit: 'g'
            },
        });

        return response.data
    } catch (error) {
        console.error('Error fetching metal rates:', error);
    }
}

