import StorageService from "../services/storage-service";
import date from "date-and-time";
import {fetchTableData} from "./get-data"; // Zaimportuj swój StorageService

export async function fetchAndStoreCurrencies() {
    const currencyStorage = new StorageService('currencies-today');
    const today = new Date();
    const formattedDate = date.format(today, 'YYYY-MM-DD');

    // Sprawdź, czy data ostatniego pobrania różni się od dzisiejszej
    if (localStorage.getItem('lastFetchDate') !== formattedDate || !localStorage.getItem('currencies-today')) {
        const res = await fetchTableData("A");

        res.data[0].rates.forEach((rate) => {
            console.log(rate);
            currencyStorage.saveWithId(rate.currency, rate);
        })

        //localStorage.setItem('lastFetchDate', formattedDate);
    }
}