import BasicComponents from "../../components/basic-components";
import {fillData} from "../currencies/table-currencies";
import {createCurrentGoldPrice} from "../../components/current-gold";
import {initMetalTable} from "./metals-table";
import {fetchMetalRates} from "../../../api/get-data";
import StorageService from "../../../services/storage-service";
import {fetchAndStoreMetals} from "../../../api/metals-cache";

export const initMetals = async () => {
    const metalsComponents = new BasicComponents()
    const ms = new StorageService("metals");

    console.log(ms.getAllKeys());

    metalsComponents.createH1("Metale", "mainArea")
    metalsComponents.addIconToHeading("fa-solid fa-medal color-2")

    metalsComponents.createP(`Ceny metali szlachetnych`, "alert alert-success m-0 mb-4 alert-dismissible fade show", "mainArea")

    metalsComponents.createP(`Dwiedz się wiecej na temat inwestycji w metale klikjąc w przycisk "Zobacz dane inwestycyjne"`, "alert alert-success m-0 mb-4 alert-dismissible fade show", "mainArea")

    metalsComponents.createDiv("metals-cotainer", "container my-5")
    metalsComponents.createAndAppendDiv("grid-container", "row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4", "metals-cotainer")

    metalsComponents.createDiv("investment-info-container", "mt-5 p-3 border rounded bg-light d-none")
    metalsComponents.createH2("Dane inwestycyjne", "investment-info-container", "investment-title" )
    metalsComponents.createPWithId("Wybierz metal, aby zobaczyć szczegóły inwestycyjne.", "investment-data", "", "investment-info-container")

    await fetchAndStoreMetals()

    const metalsStorage = new StorageService("metals")

    const metals = [
        {
            name: 'Aluminium',
            value: metalsStorage.findOne("aluminium"),
            icon: 'fa-bolt',
            color: 'text-primary',
            investmentData: 'Aluminium jest szeroko wykorzystywane w przemyśle motoryzacyjnym, lotniczym oraz budowlanym. Jego wartość inwestycyjna jest umiarkowana z powodu stabilnego popytu, ale potencjalne innowacje w produkcji mogą zwiększyć jego znaczenie na rynku. Inwestycje w aluminium są stabilne, ale wrażliwe na zmiany globalnych cen energii, ponieważ produkcja wymaga dużych nakładów energii.'
        },
        {
            name: 'Miedź',
            value: metalsStorage.findOne("miedź"),
            icon: 'fa-coins',
            color: 'text-success',
            investmentData: 'Miedź jest kluczowym metalem w przemyśle elektrycznym, budownictwie oraz w produkcji pojazdów elektrycznych. Jej cena waha się w zależności od globalnego zapotrzebowania, rozwoju technologii i zmian w polityce klimatycznej. Rośnie zainteresowanie recyklingiem miedzi, co może wpływać na długoterminowy popyt i podaż na rynku.'
        },
        // {
        //     name: 'Złoto',
        //     value: metalsStorage.findOne("złoto"),
        //     icon: 'fa-ring',
        //     color: 'text-warning',
        //     investmentData: 'Złoto jest uważane za „bezpieczną przystań” w okresach niestabilności gospodarczej. Jego wartość wzrasta w trudnych czasach, zwłaszcza podczas kryzysów finansowych i politycznych. Złoto ma także szerokie zastosowanie w jubilerstwie i technologii. Inwestycje w złoto mogą zapewnić ochronę przed inflacją oraz zmiennością rynków finansowych.'
        // },
        {
            name: 'Ołów',
            value: metalsStorage.findOne("ołów"),
            icon: 'fa-anchor',
            color: 'text-secondary',
            investmentData: 'Ołów znajduje szerokie zastosowanie w produkcji akumulatorów, zwłaszcza w motoryzacji. Jego inwestycyjna atrakcyjność jest ograniczona przez obawy o środowisko oraz spadający popyt na tradycyjne akumulatory. Ołów jest metalem o niskiej dynamice wzrostu, a jego cena jest wrażliwa na zmiany regulacji środowiskowych.'
        },
        {
            name: 'Nikiel',
            value: metalsStorage.findOne("nikiel"),
            icon: 'fa-cog',
            color: 'text-danger',
            investmentData: 'Nikiel jest niezbędny w produkcji stali nierdzewnej oraz baterii do pojazdów elektrycznych. Popyt na ten metal rośnie wraz z ekspansją rynku EV, co czyni go jednym z bardziej dynamicznych metali. Ceny niklu mogą być niestabilne w zależności od dostępności surowca i wahań w produkcji baterii.'
        },
        {
            name: 'Palad',
            value: metalsStorage.findOne("palad"),
            icon: 'fa-gem',
            color: 'text-info',
            investmentData: 'Palad jest szeroko wykorzystywany w przemyśle motoryzacyjnym, szczególnie w produkcji katalizatorów. Jego cena wzrosła w ostatnich latach z powodu wzrastającego zapotrzebowania, zwłaszcza w krajach rozwijających się. Jako metal rzadki, palad ma także potencjał w długoterminowych inwestycjach, ale jego ceny mogą być niestabilne, zależnie od globalnego zapotrzebowania na samochody.'
        },
        {
            name: 'Platyna',
            value: metalsStorage.findOne("platyna"),
            icon: 'fa-diamond',
            color: 'text-dark',
            investmentData: 'Platyna znajduje zastosowanie głównie w przemyśle motoryzacyjnym oraz jubilerstwie. Jest mniej popularna niż złoto, ale może stanowić dobrą alternatywę inwestycyjną. Jest metalem bardziej zmiennym, a jego cena w dużej mierze zależy od popytu w przemyśle motoryzacyjnym i zastosowań w technologii ekologicznej.'
        },
        {
            name: 'Srebro',
            value: metalsStorage.findOne("srebro"),
            icon: 'fa-snowflake',
            color: 'text-primary',
            investmentData: 'Srebro jest wykorzystywane zarówno w przemyśle, jak i jako inwestycja. Jego wartość zależy od globalnego zapotrzebowania przemysłowego, szczególnie w elektronice, oraz trendów makroekonomicznych. W porównaniu do złota, srebro jest bardziej zmienne, ale także bardziej dostępne, co czyni je interesującym wyborem dla długoterminowych inwestycji.'
        },
        {
            name: 'Cynk',
            value: metalsStorage.findOne("cynk"),
            icon: 'fa-toolbox',
            color: 'text-secondary',
            investmentData: 'Cynk jest szeroko stosowany w budownictwie oraz produkcji ocynkowanych materiałów. Jest to stabilna inwestycja z umiarkowanym wzrostem, ponieważ zapotrzebowanie na cynk jest związane z rozwojem infrastruktury i przemysłu. Ceny cynku mogą wzrastać w okresach intensywnej urbanizacji i rozwoju gospodarczego.'
        },
    ];

    const container = document.getElementById('grid-container');
    const infoContainer = document.getElementById('investment-info-container');
    const infoTitle = document.querySelector('.investment-title');
    const infoData = document.getElementById('investment-data');

    // Funkcja tworząca kartę
    function createCard(metal) {
        // Tworzymy kolumnę
        const col = document.createElement('div');
        col.className = 'col';

        // Tworzymy kartę
        const card = document.createElement('div');
        card.className = 'card text-center shadow-sm';

        // Tworzymy ciało karty
        const cardBody = document.createElement('div');
        cardBody.className = 'card-body';

        // Ikona
        const icon = document.createElement('i');
        icon.className = `fas ${metal.icon} ${metal.color} fs-3`;
        cardBody.appendChild(icon);

        // Tytuł
        const title = document.createElement('h5');
        title.className = 'card-title mt-3';
        title.textContent = metal.name;
        cardBody.appendChild(title);

        // Tekst
        const text = document.createElement('p');
        text.className = 'card-text';
        text.textContent = metal.value;
        cardBody.appendChild(text);

        // Przycisk do wyświetlenia danych inwestycyjnych
        const button = document.createElement('button');
        button.className = 'btn mt-3 bg-color-3 color-5';
        button.textContent = 'Zobacz dane inwestycyjne';
        button.onclick = function() {
            showInvestmentData(metal.name);
        };
        cardBody.appendChild(button);

        // Dodajemy ciało do karty
        card.appendChild(cardBody);

        // Dodajemy kartę do kolumny
        col.appendChild(card);

        return col;
    }

    // Funkcja wyświetlająca dane inwestycyjne
    function showInvestmentData(metalName) {
        const metal = metals.find(m => m.name === metalName);

        // Ustawiamy tytuł i dane
        infoTitle.textContent = `Dane inwestycyjne dla ${metal.name}`;
        infoData.textContent = metal.investmentData;

        // Pokazujemy kontener z danymi
        infoContainer.classList.remove('d-none');
    }

    // Dodajemy wszystkie dane do kontenera
    metals.forEach(metal => {
        const card = createCard(metal);
        container.appendChild(card);
    });


    // const res = await fetchMetalRates()
    //
    // console.log(res)

    // const container = document.getElementById('metals-cotainer');
    //
    // const getMetal = (key, metalPL) =>{
    //     const p = document.createElement('p');
    //     p.className = "list-group-item m-0"
    //     p.textContent = `${metalPL}: ${res.metals[key]}`;
    //     container.appendChild(p);
    // }
    //
    //
    // getMetal("aluminum", "aluminium");
    // getMetal("copper", "miedź");
    // getMetal("gold", "złoto");
    // getMetal("lead", "ołów");
    // getMetal("nickel", "nikiel");
    // getMetal("palladium", "palad");
    // getMetal("platinum", "platyna");
    // getMetal("silver", "srebro");
    // getMetal("zinc", "cynk");




}






