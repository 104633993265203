import 'jquery'
import $ from 'jquery';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../css/style.scss'
import {createHome} from "./ui/pages/home";
import Time from "./ui/components/time"
import {fillData} from "./ui/pages/currencies/table-currencies";
//import {DataTable} from "simple-datatables"
import button from "bootstrap/js/src/button";
import {toggleMenu} from "./ui/components/toggle-menu";
import {handlers} from "./handlers/handlers";
import DataTable from "datatables.net-dt";
import GoldPriceService from "./api/cache";
import {fetchAndStoreCurrencies} from "./api/currency-cache";

 const main = () => {
     toggleMenu()
     const t = new Time
     t.generateTime()
     createHome().catch(error => {
         console.log(error);})
     handlers()

     const goldPriceService = new GoldPriceService();

     goldPriceService.fillPastYear(new Date().toISOString().split('T')[0]);
     const loadedDateMap = goldPriceService.loadMapFromLocalStorage();
     console.log("Mapa wczytana z localStorage:", loadedDateMap);

     fetchAndStoreCurrencies()

     goldPriceService.fetchGoldPricesIfNeeded();

 }

 main()

$(document).ready(function() {
    console.log("jQuery działa poprawnie!");
});


